/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { eventCallbackTriggers } from "../templates/wppage";
import DonationLayout from "../layout/DonationLayout";

const pageContent =
  '<gatsby_donation amount="0" donationid="33" btntext="Recurring donation Registration"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Hearfulness Institute - Donation, Recurring" isrecurring="true"/>';
const PageContent = React.memo(PageContentNonMemoized);

const RecurringDonationHeartfulnessInstitute = () => {
  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers(
        "recurring-donations-for-hearfulness-institute",
        event
      ),
    []
  );

  return (
    <DonationLayout
      seoData={{ title: "Heartfulness Institute Recurring Donations" }}
    >
      <Grid sx={{ my: 3, textAlign: "center" }}>
        <Box>
          <p>Click the below button to donate. </p>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent}
          />
        </Box>
      </Grid>
    </DonationLayout>
  );
};

export default RecurringDonationHeartfulnessInstitute;
